import { AsProps, CSSProps, keyframes, styled } from '@neui/core';
import { VStack } from '@neui/layout';
import { forwardRef, HTMLAttributes, ReactNode } from 'react';
import { Content } from '@radix-ui/react-accordion';

import { BannerIconBadgeProps } from './BannerIconBadge';
import { BannerTextBadgeProps } from './BannerTextBadge';

export type BannerCollapsibleContentProps = AsProps &
  CSSProps &
  BannerIconBadgeProps &
  BannerTextBadgeProps &
  HTMLAttributes<HTMLDivElement> & {
    customIcon?: ReactNode;
    badgeContent?: string | ReactNode;
    actions?: ReactNode;
  };

const slideDown = keyframes({
  from: { height: 0 },
  to: { height: 'var(--radix-accordion-content-height)' },
});

const slideUp = keyframes({
  from: { height: 'var(--radix-accordion-content-height)' },
  to: { height: 0 },
});

const Container = styled(Content, {
  paddingTop: '$component-4',
  overflow: 'hidden',
  gap: '$component-4',
  '&[data-state="open"]': {
    animation: `${slideDown} 150ms ease-out`,
  },
  '&[data-state="closed"]': {
    animationFillMode: 'forwards !important', // NOTE: workaround to avoid the animation flickering
    animation: `${slideUp} 150ms ease-out`,
  },
});

const ActionContainer = styled(VStack, {
  alignItems: 'flex-end',
});

export const BannerCollapsibleContent = forwardRef<
  HTMLDivElement,
  BannerCollapsibleContentProps
>(
  (
    {
      customIcon,
      icon,
      badgeContent,
      variant = 'outline',
      look = 'supplementary',
      actions,
      size,
      children,
      ...rest
    },
    forwardedRef,
  ) => {
    return (
      <Container ref={forwardedRef} {...rest}>
        <VStack spacing="$component-4" css={{ width: '100%', paddingX: 64 }}>
          {children}
        </VStack>
        {actions && (
          <ActionContainer
            spacing="$component-6"
            css={{ justifySelf: 'flex-end' }}
          >
            {actions}
          </ActionContainer>
        )}
      </Container>
    );
  },
);
BannerCollapsibleContent.displayName = 'BannerCollapsibleContent';
