/* eslint-disable @next/next/no-img-element */
import { VStack } from '@neui/layout';
import { Headline } from '@neui/styleguide-commerzbank';
import { Section } from '@components/neui-components/atoms/Section';
import { useTracker } from '@utils/snowplowTracking';
import { NewsBanner } from '@components/NewsBanner/NewsBanner';
import { useTranslation } from '@utils/i18n';
import { useRuntimeSettings } from '@utils/config';
import { ChangeServicePortal } from '@components/ChangeServicePortal';
import { MostReadArticles } from '@components/MostReadArticles/MostReadArticles';
import { Categories } from '@components/Categories/Categories';
import { MakeAppointment } from '@components/MakeAppointment/MakeAppointment';
import { SelfServiceIconGroup } from '@components/SelfServiceIconGroup/SelfServiceIconGroup';

import { cdsIconGroupInfo } from '@static-content/iconGroupInfo';
import { makeStagedLinkFunction } from '@utils/helpers/linkHelpers';

import { CdsIndexPageProps } from '../pages/index';

export const CdsIndexPage = ({ topCategories }: CdsIndexPageProps) => {
  const {
    build: { stage },
    staticContent: { mostReadArticles, newsData },
  } = useRuntimeSettings();
  const { $t } = useTranslation();
  const { trackButtonClick } = useTracker(CdsIndexPage.name);
  const makeStagedLink = makeStagedLinkFunction(stage);

  return (
    <>
      <Section>
        <Headline type={'h3'} renderAs="h1">
          {$t('SEARCH_HEADLINE')}
        </Headline>
        <VStack spacing={'$subsection'}>
          {newsData.length > 0 && <NewsBanner news={newsData[0]} />}
          <SelfServiceIconGroup
            actionIcons={cdsIconGroupInfo(makeStagedLink)}
            sectionNumber={1}
            sectionTotal={4}
          />
        </VStack>
      </Section>
      <MostReadArticles
        trackButtonClick={trackButtonClick}
        mostReadArticles={mostReadArticles}
      />
      <Categories
        topCategories={topCategories}
        sectionNumber={3}
        sectionTotal={4}
      />
      <ChangeServicePortal />
      <MakeAppointment />
    </>
  );
};
