import { styled } from '@neui/core';
import { VStack } from '@neui/layout';
import { IconThumbnail, Typography } from '@neui/styleguide-commerzbank';

import { ClickItem } from '@components/neui-components/atoms/ClickItem';
import { StyledSection } from 'page-templates/OBIndexPage';
import { useTracker } from '@utils/snowplowTracking';
import { GA4TrackLinkClick } from '@utils/tracking';
import { useRuntimeSettings } from '@utils/config';

export const MakeAppointment = () => {
  const {
    tracking: {
      ga4: { enabled: enableGA4Tracking },
    },
  } = useRuntimeSettings();

  const appointmentUrl =
    'https://www.commerzbank.de/kontakt/terminvereinbarung-split/';
  const appointmentText = 'Termin vereinbaren';

  const { trackLinkClick } = useTracker(MakeAppointment.name);

  const handleAppointmentClick = () => {
    if (enableGA4Tracking) {
      GA4TrackLinkClick(appointmentUrl, appointmentText, undefined, 'Homepage');
    }
    trackLinkClick?.(appointmentUrl, appointmentText);
  };
  return (
    <StyledSection>
      <StyledClickItem
        iconText={appointmentText}
        href={appointmentUrl}
        onClick={handleAppointmentClick}
      >
        <VStack
          flexDirection={'row'}
          alignItems={'center'}
          spacing={'$component-4'}
        >
          <StyledIconThumbnail icon={{ icon: 'object___calender' }} />
          <VStack>
            <Typography
              weight={'medium'}
              size={5}
              role="heading"
              aria-level={3}
            >
              Sie haben ein individuelles Anliegen?
            </Typography>
            <Typography size={7} color={'helper'}>
              Vereinbaren Sie einen Termin für Ihre persönliche Beratung per
              Telefon, Video oder in Ihrer Filiale.
            </Typography>
          </VStack>
        </VStack>
      </StyledClickItem>
    </StyledSection>
  );
};

const StyledIconThumbnail = styled(IconThumbnail, {
  minWidth: '50px !important',
});

const StyledClickItem = styled(ClickItem, {
  maxWidth: 792,
});
