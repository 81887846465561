import { AsProps, CSSProps, styled } from '@neui/core';
import { Item } from '@radix-ui/react-accordion';
import { forwardRef } from 'react';

import type { AccordionItemProps as RadixItemProps } from '@radix-ui/react-accordion';

export type BannerCollapsibleItemProps = AsProps &
  CSSProps &
  RadixItemProps & {};

const StyledItem = styled(Item, {
  width: '100%',
  '&:not(:last-child)': {
    borderBottom: '1px solid $strong-line',
  },
});

export const BannerCollapsibleItem = forwardRef<
  HTMLDivElement,
  BannerCollapsibleItemProps
>(({ ...rest }, forwardedRef) => {
  return <StyledItem ref={forwardedRef} {...rest} />;
});
BannerCollapsibleItem.displayName = 'BannerCollapsibleItem';
