import { AsProps, CSSProps, styled } from '@neui/core';
import { Trigger } from '@radix-ui/react-accordion';
import { forwardRef, ReactNode } from 'react';
import { interaction_arrows_chevronDown } from '@neui/styleguide-commerzbank';
import { Box, HStack, VStack } from '@neui/layout';

import { Icon } from './Icon';
import { BannerIconBadge, BannerIconBadgeProps } from './BannerIconBadge';
import { BannerTextBadge, BannerTextBadgeProps } from './BannerTextBadge';

import type { CollapsibleTriggerProps as RadixTriggerProps } from '@radix-ui/react-accordion';

export type BannerCollapsibleTriggerProps = AsProps &
  CSSProps &
  BannerIconBadgeProps &
  BannerTextBadgeProps &
  RadixTriggerProps & {
    headline: ReactNode;
    customIcon?: ReactNode;
    badgeContent?: string | ReactNode;
  };

// const Overlay = styled(Box, {
//   transition: 'all 100ms ease-in',
//   content: "''",
//   position: 'absolute',
//   backgroundColor: 'red',
//   opacity: 0,
//   zIndex: 0,
//   borderRadius: '$space$component-4',
//   height: '100%',
//   top: 0,
//   bottom: 0,
//   left: 'calc($space$component-4 * -1)',
//   right: 'calc($space$component-4 * -1)',
// });

const Container = styled(HStack, {
  width: '100%',
  display: 'flex',
  gap: '$component-4',
  alignItems: 'flex-start',
  '@sm': {
    alignItems: 'center',
  },
});

const IconWrapper = styled(Box, {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  position: 'relative',
  height: '100%',
  width: 24,
  right: 0,
  top: 0,
  bottom: 0,
  '@sm': {
    alignItems: 'center',
  },
});

const StyledIcon = styled(Icon, {
  zIndex: 1,
  transition: 'all 100ms ease-out',
  transform: 'rotate(0deg)',
});

const StyledTrigger = styled(Trigger, {
  all: 'unset',
  position: 'relative',
  cursor: 'pointer',
  display: 'flex',
  // paddingY: '$component-4',
  paddingX: 0,
  width: '100%',
  '@md': {
    width: '100%',
    // paddingY: '$component-6',
    paddingX: 0,
  },
  '&[data-state="open"]': {
    [`& ${StyledIcon}`]: {
      transform: 'rotate(-180deg)',
    },
  },
  '&:focus': {
    [`& ${StyledIcon}`]: {
      outline: 'auto',
    },
  },
});

export const BannerCollapsibleTrigger = forwardRef<
  HTMLButtonElement,
  BannerCollapsibleTriggerProps
>(
  (
    {
      headline,
      customIcon,
      icon,
      badgeContent,
      size,
      look = 'supplementary',
      variant = 'outline',
      children,
      renderAs = 'span',
      ...rest
    },
    forwardedRef,
  ) => {
    return (
      <StyledTrigger ref={forwardedRef} {...rest}>
        <Container>
          {customIcon
            ? customIcon
            : icon && (
                <BannerIconBadge
                  size={size}
                  variant={variant}
                  icon={icon}
                  look={look}
                />
              )}
          <Box css={{ width: '100%' }}>
            {badgeContent ? (
              <VStack alignItems="flex-start">
                <BannerTextBadge look={look}>{badgeContent}</BannerTextBadge>
                {children}
              </VStack>
            ) : (
              children
            )}
            {headline && headline}
          </Box>
          <IconWrapper>
            <StyledIcon size="small" icon={interaction_arrows_chevronDown} />
          </IconWrapper>
        </Container>
      </StyledTrigger>
    );
  },
);
BannerCollapsibleTrigger.displayName = 'BannerCollapsibleTrigger';
