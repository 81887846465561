import { GetStaticPropsResult } from 'next';

import Layout from '@components/Layout/Layout';
import { CdsIndexPage } from 'page-templates/CdsIndexPage';
import CdsLayout from '@components/Layout/CdsLayout';
import { FkIndexPage } from 'page-templates/FkIndexPage';

import { CategoryPageProps } from './[...slug]';
import { NextPageWithLayout, PageProps } from './_app';

export type { MostReadArticleType } from '@components/MostReadArticles/MostReadArticles';

export type NewsDataType = {
  title: string;
  content: string;
  subline: string;
  url?: string;
};

export type FkIndexPageProps = {
  query?: string;
  title: string;
  topCategories: CategoryPageProps[];
};

export type CdsIndexPageProps = {
  query?: string;
  title: string;
  topCategories: CategoryPageProps[];
};

export type IndexPageProps = CdsIndexPageProps | FkIndexPageProps;

const IndexPage: NextPageWithLayout<PageProps<IndexPageProps>> = ({
  config,
  ...props
}) => {
  return config.server.site.basename.includes('gpp') ? (
    <FkIndexPage {...props} />
  ) : (
    <CdsIndexPage {...props} />
  );
};

IndexPage.getLayout = function getLayout(page, props) {
  const PageLayout = props.config.server.site.basename.includes('gpp')
    ? Layout
    : CdsLayout;

  return (
    <PageLayout pageType={'Homepage'} title={props.title} slug={'/'}>
      {page}
    </PageLayout>
  );
};

export async function getStaticProps(): Promise<
  GetStaticPropsResult<PageProps<IndexPageProps>>
> {
  const { DataFetcher } = await import('@utils/server/DataFetcher');
  const { ConfigLoader } = await import('@utils/server/ConfigLoader');
  const configLoader = new ConfigLoader();
  const dataFetcher = new DataFetcher(configLoader);

  // NOTE(benjamin): This should be ideally called asynchronously
  const dataProps = dataFetcher.getIndexProps();
  const configProps = dataFetcher.getConfigProps();

  if ('props' in dataProps) {
    return {
      ...dataProps,
      props: {
        ...dataProps.props,
        config: configProps,
      },
    };
  }
  return dataProps;
}

export default IndexPage;
